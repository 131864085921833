import React, { Suspense, lazy, useState, useCallback, useEffect } from "react"
import Helmet from "react-helmet"
import { Router } from "@reach/router"
import InViewObserver from "../components/InViewObserver"

import Link from "../components/LinkWithTransitionLock";
import Page_Footer from './page_footer'
// import Scrollbar from 'smooth-scrollbar';

import { TimelineMax, TweenMax, TweenLite, Linear, TextPlugin, ThrowPropsPlugin, EasePack, CSSPlugin, ScrollToPlugin, Power1, Power2, Power4, Expo, gsap } from 'gsap';

class Page extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount () {
    
    InViewObserver()
    
  }
 
  componentWillUnmount() {

  }


  render() {

    return (
      // <>

      <div className="content_wrap">

        <div className="page_header">
          <h2 className="sub_title">台北萬華　最美的是人</h2>
          <h1 className="main_title">禾順商旅<a href="https://www.facebook.com/yourhoteltaipei" className="location" target="_blank">↗</a></h1>
          <h6 className="speaker">店主/徐舒涵　筆者/林立青</h6>
        </div>

        <div className="page_content">

          <section className="row_area_s project_a_muse_5_a">
            <div className="column_4">
              <div className="context">
                <p>在 COVID-19 疫情肆虐時，台北市到處在找尋願意提供警消醫護輪班休息的地方，而第一家願意公開表示支持的，正是位於梧州及廣州街口的禾順商旅，在本月剛正式成為台北第一家的警消醫護加油棧，經過當地商家的說明，才知到華西街大火時，受災的住戶現在也都被妥善的安置於此。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_5_b">
            <div className="column_8">
              <div className="video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/mtKeIwus4Xs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_5_c">
            <div className="column_7 onset_left_1">
              <div className="context">
                <h4>這家旅社，不只是愛鄉愛土愛艋舺，同時也張開雙手，擁抱來到台北市的國際旅客。</h4>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_5_d">
            <div className="column_6 onset_left_1">
              <div className="context">
                <p>禾順商旅位於萬華區夜市正中心，旁有梧州街夜市、廣州街夜市、華西街夜市及可說是四夜市共構，更有萬華四大廟宇加持，觀光資源及生活機能極為便利，美食名店群聚，古剎名勝聞名，近年來更因為台灣推廣國際觀光以後，各國旅客紛紛前來體驗台灣在地夜市文化，這裡距離西門町僅有一站，距離台北車站也只要兩站，便利的交通和緊鄰的生活機能帶來的是接地氣的體驗，還和在地的商圈名店結合各種導覽，這讓禾順商旅更有優勢，也更好和在地的商圈名店結合。</p>
                <p>這裡處於交通樞紐因此住宿來客多元，搭乘鐵路的國內旅客、來老區逛街的觀光客、投宿的背包客，甚至有在地人租房作為結婚新房，參拜神靈的信眾參加慶典亦會到此投宿。禾順的大廳還提供各種手冊與名店指南，讓招待的服務員，能一眼看出來客手上的圖片，看圖指引路和店名。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_5_e">
            <div className="column_5 onset_left_1">
              <div className="context">
                <p>總經理徐舒涵經營商旅的付出和用心在禾順商旅隨處可見，很難想像萬華中心會有旅社在一樓辦有藝文常設展，有時是地方文化介紹，有時是當地的捏陶玩偶。旅社後方的庭院空間，保留了紅磚瓦牆面，辦理小型活動，或作為吸菸區。旅社每個房間均有不同主題，經過設計的空間看起來更寬敞，這間位於夜市中央的三星級飯店，網路訂房經常客滿，靠著正是對外的開放透明，及對在地的友善支持。</p>
                <p>每當艋舺一帶有弱勢服務單位需要募集餐點時，就有機會嘗到禾順商旅主廚的精湛手藝，那些餐點原是用來招待國際旅客的精美佳餚，徐舒涵不只出動主廚送餐，也常以個人名義購捐名店佳餚給社福機構，讓在地弱勢者共享盛宴。</p>
                <p>作為地方的旅館，畢竟要先符合當地人的鑑賞，對當地人來說，禾順商旅是一個具有在地文化溫度的空間場域，不僅線上訂房熱門，口碑更奠定在地人及往來的各國旅客的認同，連帶提振周邊的商家夜市商機，旅社的大門口，敞亮的照明，也讓周遭的夜市便的更加宜人。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_5_f">
            <div className="column_5">
              <div className="context">
              </div>
            </div>
          </section>
        </div>

        <div className="page_footer">
          <Link to="/project_a_muse_6" className="next_page">
            <div className="next_title">福大同</div>
            <div className="next_owner">店主/蔡子晴　筆者/林立青</div>
          </Link>
        </div>

      </div>

      // </>
    )

  }

}


export default Page

